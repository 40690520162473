import React from "react";

const TikTokIcon = (): JSX.Element => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7498 10.1431C19.0297 10.1472 17.3517 9.61072 15.9529 8.60947V15.5925C15.9525 16.8858 15.5571 18.1482 14.8199 19.2108C14.0826 20.2734 13.0385 21.0856 11.8271 21.5387C10.6158 21.9919 9.29495 22.0645 8.04127 21.7467C6.78758 21.4289 5.66078 20.736 4.81154 19.7606C3.9623 18.7851 3.4311 17.5736 3.28896 16.2881C3.14683 15.0026 3.40054 13.7044 4.01616 12.567C4.63179 11.4296 5.57999 10.5072 6.73397 9.92323C7.88796 9.33926 9.19273 9.12151 10.4738 9.2991V12.8113C9.88758 12.6269 9.25808 12.6325 8.6752 12.8272C8.09232 13.0219 7.58587 13.3958 7.22817 13.8955C6.87048 14.3952 6.67983 14.9952 6.68344 15.6097C6.68706 16.2243 6.88477 16.8219 7.24833 17.3174C7.61189 17.8129 8.12271 18.1808 8.70784 18.3686C9.29297 18.5564 9.9225 18.5546 10.5065 18.3633C11.0905 18.172 11.5992 17.8011 11.9598 17.3035C12.3204 16.8059 12.5146 16.207 12.5146 15.5925V1.94138H15.9529C15.9505 2.23174 15.9749 2.52171 16.0256 2.80761C16.1451 3.44585 16.3935 4.05301 16.7557 4.59194C17.1179 5.13088 17.5862 5.59026 18.132 5.942C18.9085 6.45545 19.8189 6.72911 20.7498 6.72892V10.1431Z"
          fill="#10247E"
        />
      </svg>
    </div>
  );
};

export default TikTokIcon;
